import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const TravelIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.posts.edges

  return (
    <Layout location={location} title={siteTitle} data={data}>
      <SEO title="Travel posts" />
      {/* <Bio /> */}
      
      {/* {tags && tags.map( tag => {
        console.log(tag)
        return (
          <p key={tag} className={styles.tag} to={`/tags/${_.kebabCase(tag)}`} >
            {tag}
          </p>
        )
      })} */}

      {/* <div className="tag-container">
        {tags.map(tag => {
          return (
            <Link
              key={tag}
              // style={{ textDecoration: "none" }}
              className={styles.tag}
              to={`/tags/${_.kebabCase(tag)}`}
            >
              @{tag}
            </Link>
          )
        })}
      </div> */}

      <div style={{maxWidth: `800px`, margin: `auto`}}>
        <p style={{color: `#0b1f4be6`}}>Coming soon....</p>
      </div>

      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default TravelIndex

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blogs/travel/" } }
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

// export default props => (
//   <StaticQuery
//     query={indexQuery}
//     render={data => (
//       <ElementsPage location={props.location} data={data} {...props} />
//     )}
//   />
// )
